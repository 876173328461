import React from 'react'
import ResAppBar from './ResAppBar'
import styles from './Header.module.css'

function Header({blogRoot}) {
	return (
      <header className={styles.allSiteHeader}>
        <ResAppBar />
      </header>
	)
}

export default Header





