import React from 'react'
import styles from './Footer.module.css'
import { Link, useCurrentRoute } from 'react-navi'
import SubscribeForm from './SubscribeForm'
import BuyCoffee from './BuyCoffee'

function Footer({blogRoot}) {
	let { data } = useCurrentRoute()
	return (
		<footer className={styles.footer}>
			<section className={styles.nextPost}>
			  {
			    data.previousDetails &&
			    <Link className={styles.previous} href={data.previousDetails.href}>
			      ← {data.previousDetails.title}
			    </Link>
			  }
			  {
			    data.nextDetails &&
			    <Link className={styles.next} href={data.nextDetails.href}>
			      {data.nextDetails.title} →
			    </Link>
			  }
			</section>
			<div className={styles.linkContainer}>
				<div>
					<Link href={blogRoot}>home</Link> {' | '}
					<Link href="/about">about</Link> {' | '}
					<Link href="/">posts</Link> {' | '}
					<Link href="/tags">topics</Link> {' | '}
					<Link href="/links">links</Link>{' | '}
					<Link href="/subscribe">subscribe</Link>
				</div>
				<div className={styles.footerSubscribe}>
					<SubscribeForm />
					<BuyCoffee />
				</div>
			</div>
		</footer>
	)
}

export default Footer



