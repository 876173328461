import React from 'react'
import styles from './SubscribeForm.module.css'
 
function SubscribeForm(props) {
  return (
    <div className={styles.subscribeForm}>
    	<SubstackForm />
	</div>
  )
}


class SubstackForm extends React.Component {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;

    return (
   		<iframe className={styles.hideForm + (loaded ? " " + styles.showForm : "")} onLoad={this.onLoad} width="300" src="https://shitididntknow.substack.com/embed" scrolling="no" frameBorder="0" title="substack-subscribe"></iframe>
    );
  }
}


export default SubscribeForm