import React from 'react'
import ArticleSummary from './ArticleSummary'
import Header from './Header'
import Footer from './Footer'
import Pagination from './Pagination'
import styles from './BlogIndexPage.module.css'

function BlogIndexPage({ blogRoot, pageCount, pageNumber, postRoutes }) {
  return (
    <div>
      <Header blogRoot={blogRoot} />

      <div className={`${styles.blogIndexContent}`}>
        <ul className={styles.articlesList}>
          {postRoutes.map(route => (
            <li key={route.url.href}>
              <ArticleSummary blogRoot={blogRoot} route={route} />
            </li>
          ))}
        </ul>
        <div className={styles.pagination}>
          {pageCount > 1 && (
            <Pagination
              blogRoot={blogRoot}
              pageCount={pageCount}
              pageNumber={pageNumber}
            />
          )}
        </div>
      </div>

      <Footer blogRoot={blogRoot} />
    </div>
  )
}

export default BlogIndexPage
