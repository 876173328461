import React, { Component } from 'react';
import { Link } from 'react-navi';
import {
  AppBar, Toolbar, Typography, List, ListItem,
  Grid, SwipeableDrawer
} from '@material-ui/core';
import styles from './ResAppBar.module.css'
import MenuIcon from '@material-ui/icons/Menu';


class ResAppBar extends Component{
  constructor(props){
    super(props);
    this.state = {drawerActivate:false, drawer:false, shouldShow: null};
    this.lastScroll = null;
    this.createDrawer = this.createDrawer.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    if(window.innerWidth <= 740){
      this.setState({drawerActivate:true});
    }

    window.addEventListener('resize',()=>{
      if(window.innerWidth <= 740){
        this.setState({drawerActivate:true});
      }
      else{
        this.setState({drawerActivate:false})
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  
  handleScroll(evt) {
    const lastScroll = window.scrollY;

    if (lastScroll === this.lastScroll) {
      return;
    }

    const shouldShow = (this.lastScroll !== null) ? (lastScroll < this.lastScroll || lastScroll < 400) : null;
    if (shouldShow !== this.state.shouldShow) {
      this.setState((prevState, props) => ({
        ...prevState,
        shouldShow,
      }));
    }

    this.lastScroll = lastScroll;
  }


  //Small Screens
  createDrawer(){
    return (
      <div>
        <AppBar position="fixed"
                className={
                  `${styles.appBarRoot} ${
                    this.state.shouldShow === null ? '' : (
                      this.state.shouldShow ? styles.show : styles.hide
                    )
                  }`
        }>
          <Toolbar>
            <Grid container direction = "row" justify = "space-between" alignItems="center">
              <MenuIcon
                className = {styles.sideBarIcon}
                onClick={()=>{this.setState({drawer:true})}} />
              <div className={styles.centerMobile}>
                <img src="/the_poo.svg" alt="Me" height="50" />
                <Typography variant = "subtitle1" className={styles.centerMenuTitle}>shit i didnt know</Typography>
              </div>
              <Typography color="inherit" variant="subtitle1"></Typography>
            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
         open={this.state.drawer}
         onClose={()=>{this.setState({drawer:false})}}
         onOpen={()=>{this.setState({drawer:true})}}>

           <div
             tabIndex={0}
             role="button"
             onClick={()=>{this.setState({drawer:false})}}
             onKeyDown={()=>{this.setState({drawer:false})}}>

            <List className = {styles.list}>
               <Link href="/" className = {styles.smallMenuTitle}><ListItem key = {1} button divider>home</ListItem></Link>
               <Link className={styles.smallMenuTitle} href="/about"><ListItem key = {2} button divider>start here</ListItem></Link>
               <Link className={styles.smallMenuTitle} href="/"><ListItem key = {3} button divider>posts</ListItem></Link>
               <Link className={styles.smallMenuTitle} href="/shitsngigs"><ListItem key = {6} button divider>shits n gigs</ListItem></Link>
               <Link className={styles.smallMenuTitle} href="/tags"><ListItem key = {4} button divider>topics</ListItem></Link>
               <Link className={styles.smallMenuTitle} href="/links"><ListItem key = {5} button divider>links</ListItem></Link>
               <Link className={styles.smallMenuTitle} href="/subscribe"><ListItem key = {6} button divider>subscribe</ListItem></Link>
             </List>

         </div>
       </SwipeableDrawer>

      </div>
    );
  }

  //Larger Screens
  destroyDrawer(){
    return (
      <AppBar position="fixed"
              className={
                `${styles.appBarRoot} ${
                  this.state.shouldShow === null ? '' : (
                    this.state.shouldShow ? styles.show : styles.hide
                  )
                }`
      }>
        <Toolbar className={styles.toolbarRoot}>
          <Link href="/" className = {styles.menuTitle}><img src="/the_poo.svg" alt="Me" height="50" /></Link>
          <Typography variant = "subtitle1" style={{flexGrow:1}} color="inherit" ><Link href="/" className = {styles.menuTitle}>shit i didnt know</Link></Typography>
          <Typography variant = "inherit" className = {styles.menuIcon} color="inherit" ><Link href="/about">start here</Link></Typography> {' | '}
          <Typography variant = "inherit" className = {styles.menuIcon} color="inherit" ><Link href="/">posts</Link></Typography> {' | '}
          <Typography variant = "inherit" className = {styles.menuIcon} color="inherit" ><Link href="/shitsngigs">shits n gigs</Link></Typography> {' | '}
          <Typography variant = "inherit" className = {styles.menuIcon} color="inherit" ><Link href="/tags">topics</Link></Typography> {' | '}
          <Typography variant = "inherit" className = {styles.menuIcon} color="inherit" ><Link href="/links">links</Link></Typography> {' | '}
          <Typography variant = "inherit" className = {styles.menuIcon} color="inherit" ><Link href="/subscribe">subscribe</Link></Typography>
        </Toolbar>
      </AppBar>
    )
  }

  render(){
    return(
      <div>
        {this.state.drawerActivate ? this.createDrawer() : this.destroyDrawer()}
      </div>
    );
  }
}

export default ResAppBar;