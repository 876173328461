import { join } from 'path';
import React from 'react';
import { Link } from 'react-navi';
import Chip from '@material-ui/core/Chip';
import { formatDate } from '../utils/formats'
import styles from './ArticleMeta.module.css'

function ArticleMeta({ blogRoot, meta, readingTime, hideTags, clickableTags=true }) {
  let readingTimeElement;
  if (readingTime) {
    let minutes = Math.max(Math.round(readingTime.minutes), 1)
    let cups = Math.round(minutes / 5) > 6 ? 6 : Math.round(minutes / 5); //max of 6
    readingTimeElement =
      <React.Fragment>
        <span className={styles.margin}><span className={styles.hideMobile}> &bull; </span></span>
        <span className={styles.margin}>
          {new Array(cups || 1).fill('☕️').join('')} {minutes} min read
        </span>
      </React.Fragment>
  }
  return (
    <small className={styles.container}>
      <time className={styles.margin} dateTime={meta.date.toUTCString()}>{formatDate(meta.date)}</time>
      <span className={styles.margin}><span className={styles.hideMobile}> &bull; </span></span>
      { meta.tags && !hideTags && 
        meta.tags.length &&
          <ul className={styles.tags}>
            {meta.tags.map(tag => {
              let chipComponent = (
                <Chip
                  className={clickableTags ? null : styles.margin}
                  label={tag}
                  color="primary"
                  variant="outlined"
                  clickable={clickableTags}
                />
              );
              return (
                clickableTags 
                ? 
                  (
                    <Link 
                      key={`${tag}-${meta.slug}`}
                      href={join(blogRoot, 'tags', tag)}
                      className={styles.link}
                    > {chipComponent} </Link>
                  )
                : chipComponent 
              )
            })}
          </ul>
      }
      {readingTimeElement || null}
    </small>
  )
}

export default ArticleMeta