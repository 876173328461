import React from 'react';

const BuyCoffee = (props) => {
  return (
    <a class="BmcButton" target="_blank" rel="noopener noreferrer" href="https://www.buymeacoffee.com/shitididntknow">
      <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a coffee" />
      <span class="BmcSpan">Buy me a coffee</span>
    </a>
  )
}

export default BuyCoffee